import ReactFullpage from '@fullpage/react-fullpage';
import ReactPlayer from "react-player";
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import {useState, useEffect} from "react";
import {useSwipeable} from "react-swipeable";

const anchors = [
    'intro',
    'video',
];

let scrolling = false;

const rules = [
    {
        numb: 1,
        title: 'Раскрой вкус Hoegaarden',
        descr: 'Возьмите хорошо охлаждённый бокал, это поможет вкусу Hoegaarden лучше раскрыться',
        video: require("../assets/video/1.mp4"),
        videoMb: require("../assets/video/1-v.mp4")
    },
    {
        numb: 2,
        title: 'Раскрой вкус Hoegaarden',
        descr: 'Откройте кран и дайте пиву стечь в течение 1-2 секунд, прежде чем подставить бокал',
        video: require("../assets/video/2.mp4"),
        videoMb: require("../assets/video/2-v.mp4")
    },
    {
        numb: 3,
        title: 'Раскрой вкус Hoegaarden',
        descr: 'Держите бокал под углом 45° относительно крана',
        video: require("../assets/video/3.mp4"),
        videoMb: require("../assets/video/3-v.mp4")
    },
    {
        numb: 4,
        title: 'Раскрой вкус Hoegaarden',
        descr: 'Как только бокал будет заполнен на две трети, поставьте его вертикально и долейте остаток пива',
        video: require("../assets/video/4.mp4"),
        videoMb: require("../assets/video/4-v.mp4")
    },
    {
        numb: 5,
        title: 'Раскрой вкус Hoegaarden',
        descr: 'Украсьте бокал сверху долькой свежего апельсина',
        video: require("../assets/video/5.mp4"),
        videoMb: require("../assets/video/5-v.mp4")
    }
    // {
    //     numb: 6,
    //     title: 'Насладись каждым глотком',
    //     descr: 'Открой вкус Hoegaarden в освежающей подаче',
    //     video: require("../assets/video/6.mp4"),
    //     videoMb: require("../assets/video/6-v.mp4")
    // }
]

export default function Home(){
    const [access, setAccess] = useState(0);
    const [loading, setLoading] = useState(true);
    const [curSl, setCurSl] = useState(0);
    const [loadedSl, setLoadedSl] = useState(false);
    const [screen, setScreen] = useState(0);
    const [opacities, setOpacities] = useState([])
    const [sliderRef, instanceRef] = useKeenSlider({
        slides: 5,
        initial: 0,
        loop: false,
        slideChanged(slider) {
            setCurSl(slider.track.details.rel)
        },
        created() {
            setLoadedSl(true)
        },
        detailsChanged(s) {
            const new_opacities = s.track.details.slides.map((slide) => slide.portion)
            setOpacities(new_opacities)
        },
    });

    useEffect(()=>{
        if(localStorage.getItem('age')){
            setAccess(1);
        }
        setLoading(false);
    }, []);

    const handlers = useSwipeable({
        onSwipedDown: () => {
            if(curSl > 0){
                instanceRef.current?.prev();
            }else{
                document.getElementById('move-prev').click();
            }
        },
        onSwipedUp: () => {
            if(curSl < 4){
                instanceRef.current?.next();
            }
        }
    });

    const onWheel = (e) => {
        if (e.deltaY === 0) return;
        if(!scrolling){
            if(screen === 1){
                if(curSl < 4 && e.deltaY > 0){
                    instanceRef.current?.next();
                }

                if(curSl > 0 && e.deltaY < 0){
                    instanceRef.current?.prev();
                }

                if(curSl === 0 && e.deltaY < 0){
                    document.getElementById('move-prev').click();
                }
            }

            scrolling = true;

            setTimeout(function (){
                scrolling = false;
            }, 1000);
        }
    };

    /*useEffect(() => {
        window.addEventListener("scroll", scrollHandler, true);
        return () => {
            window.removeEventListener("scroll", scrollHandler, true);
        };
    }, []);*/

    useEffect(() => {
        window.addEventListener("wheel", onWheel, true);
        return () => {
            window.removeEventListener("wheel", onWheel, true);
        };
    }, [screen, curSl]);

    return(
        <>
            {access == -1 && !loading && (
                <div className="sc sc__age fadeIn animated">
                    <div className="decor-mb-1 mb-only"/>
                    <div className="decor-mb-2 mb-only"/>
                    <div className="container d-flex align-items-center justify-content-center">
                        <div className="age__ct">
                            <div className="video__sl-numb d-flex align-items-center justify-content-center">
                                <div className="video__sl-numb-bg">
                                    <svg className="desk-only" width="142" height="164" viewBox="0 0 142 164" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.99485 41.5824L71 1.16495L141.005 41.5824V122.417L71 162.835L0.99485 122.417L0.99485 41.5824Z" fill="#F0DEC8" stroke="#262A5B" strokeWidth="2"/>
                                    </svg>
                                    <svg className="mb-only" width="47" height="53" viewBox="0 0 47 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.78711 14.0178L23.6269 1.40861L45.4667 14.0178V39.2362L23.6269 51.8454L1.78711 39.2362L1.78711 14.0178Z" fill="#F0DEC8" stroke="#262A5B" strokeWidth="2"/>
                                    </svg>
                                </div>
                                <span>18</span>
                                <i className="icon__plus">
                                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="15.3528" width="5.29412" height="36" fill="#C99700"/>
                                        <rect x="36" y="15.3529" width="5.29412" height="36" transform="rotate(90 36 15.3529)" fill="#C99700"/>
                                    </svg>
                                </i>
                            </div>
                            <div className="reject__title text-center">
                                Доступ на сайт разрешен только совершеннолетним
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {access == 0 && !loading && (
                <div className="sc sc__age fadeIn animated">
                    <div className="decor-mb-1 mb-only"/>
                    <div className="decor-mb-2 mb-only"/>
                    <div className="container d-flex align-items-center justify-content-center">
                        <div className="age__ct">
                            <div className="age__title text-center">
                                Вам <br/>
                                исполнилось <br/>
                                18 лет?
                            </div>
                            <div className="d-flex justify-content-center">
                                <div onClick={()=>{
                                    setAccess(-1);
                                    setLoading(false);
                                }} className="btn-ct btn-ct--border d-flex align-items-center justify-content-center">
                                    <div className="icon__play">
                                        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="26" cy="26" r="26" fill="#262A5B"/>
                                            <path d="M21.8065 33.5484V18.4517L32.3742 26L21.8065 33.5484Z" fill="#FEFCF5"/>
                                        </svg>
                                    </div>
                                    <span>Нет</span>
                                </div>
                                <div onClick={()=>{
                                    localStorage.setItem('age', '1');
                                    setAccess(1);
                                    setLoading(false);
                                }} className="btn-ct d-flex align-items-center justify-content-center">
                                    <span>Да</span>
                                    <div className="icon__play">
                                        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="26" cy="26" r="26" fill="#262A5B"/>
                                            <path d="M21.8065 33.5484V18.4517L32.3742 26L21.8065 33.5484Z" fill="#FEFCF5"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {access == 1 && !loading && (
                <>
                    <ReactFullpage
                        licenseKey="K1JK9-R36BH-PQ5J9-8PE67-NWMYN"
                        scrollOverflow={false}
                        anchors={anchors}
                        navigation={false}
                        onLeave={(origin, destination, direction) => {
                            setScreen(destination.index);

                            switch(destination.index){
                                case 0:
                                    document.getElementById('enable-scroll').click();
                                    break;
                                case 1:
                                    document.getElementById('disable-scroll').click();
                                    break;
                            }
                        }}
                        afterLoad={(origin, destination) => {
                            if(destination.index == 0){

                            }
                        }}
                        render={({ state, fullpageApi }) => {
                            return(
                                <ReactFullpage.Wrapper>
                                    <div className="section sc sc__promo">
                                        <div className="decor-mb-1 mb-only"/>
                                        <div className="decor-mb-2 mb-only"/>
                                        <div className="promo__ct">
                                            <div className="promo__title promo__title--1">
                                                Раскрой <br className="desk-only"/>
                                                <span>вкус</span>
                                            </div>
                                            <div className="promo__img-wrap">
                                                <div className="promo__img">
                                                    <picture>
                                                        <source media="(min-width:1024px)" srcSet={require("../assets/img/promo.png")}/>
                                                        <source media="(max-width:1023px)" srcSet={require("../assets/img/promo.png")}/>
                                                        <img src={require("../assets/img/promo.png")}/>
                                                    </picture>
                                                </div>
                                            </div>

                                            <div className="promo__title promo__title--2">
                                                охлаждённого <br/>
                                                <span>Hoegaarden</span>
                                            </div>
                                            <div className="promo__action">
                                                <div onClick={()=>{
                                                    fullpageApi.moveTo(2);
                                                }} className="btn-ct d-flex align-items-center justify-content-center">
                                                    <span>5 секретов подачи</span>
                                                    <div className="icon__play">
                                                        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="26" cy="26" r="26" fill="#262A5B"/>
                                                            <path d="M21.8065 33.5484V18.4517L32.3742 26L21.8065 33.5484Z" fill="#FEFCF5"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="hidden">
                                            <div id="disable-scroll" onClick={()=>{
                                                fullpageApi.setAllowScrolling(false);
                                            }}/>

                                            <div id="enable-scroll" onClick={()=>{
                                                fullpageApi.setAllowScrolling(true);
                                            }}/>

                                            <div id="move-prev" onClick={()=>{
                                                fullpageApi.moveTo(1);
                                            }}/>

                                            <div id="move-next" onClick={()=>{
                                                fullpageApi.moveTo(2);
                                            }}/>
                                        </div>
                                    </div>
                                    <div {...handlers} className="section sc sc__video">
                                        <div className="decor-mb-3 mb-only"/>
                                        <div className="decor-mb-4 mb-only"/>
                                        <div className="video__sl-wrap">
                                            <div className="logo desk-only">
                                                <img src={require("../assets/img/logo.png")}/>
                                            </div>
                                            <div onClick={()=>{
                                                if(curSl !== 0) {
                                                    instanceRef.current?.prev();
                                                }
                                            }}   className={'sl__nav sl__nav--prev' + (curSl === 0 ? ' disabled' : '')}>
                                                <svg className="desk-only" width="131" height="131" viewBox="0 0 131 131" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="65.5" cy="65.5" r="64.5" transform="matrix(-1 0 0 1 131 0)" stroke="#C99700" strokeWidth="2"/>
                                                    <path d="M77 65H51M51 65L60.5179 55M51 65L60.5179 75" stroke="#262A5B" strokeWidth="2"/>
                                                </svg>
                                                <svg className="mb-only" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="18" cy="18" r="17.25" transform="matrix(-1 0 0 1 36 0)" stroke="#C99700" strokeWidth="1.5"/>
                                                    <path d="M24.2748 18H13.8748M13.8748 18L17.6819 14M13.8748 18L17.6819 22" stroke="#262A5B" strokeWidth="1.5"/>
                                                </svg>
                                            </div>
                                            <div onClick={()=>{
                                                if(curSl !== 4) {
                                                    instanceRef.current?.next();
                                                }
                                            }}   className={'sl__nav sl__nav--next' + (curSl === 4 ? ' disabled' : '')}>
                                                <svg className="desk-only" width="131" height="131" viewBox="0 0 131 131" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="65.5" cy="65.5" r="64.5" stroke="#C99700" strokeWidth="2"/>
                                                    <path d="M54 65H80M80 65L70.4821 55M80 65L70.4821 75" stroke="#262A5B" strokeWidth="2"/>
                                                </svg>
                                                <svg className="mb-only" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="18" cy="18" r="17.25" stroke="#C99700" strokeWidth="1.5"/>
                                                    <path d="M11.7252 18H22.1252M22.1252 18L18.3181 14M22.1252 18L18.3181 22" stroke="#262A5B" strokeWidth="1.5"/>
                                                </svg>
                                            </div>
                                            <div className="video__sl-box">
                                                <div ref={sliderRef} className="fader">
                                                    {rules.map((it, i) => (
                                                        <div className="fader__slide" style={{ opacity: opacities[i] }}>
                                                            <div className="video__sl">
                                                                <div className="video__sl-title text-center">
                                                                    {it.title}
                                                                </div>
                                                                <div className="video__sl-media">
                                                                    <div className="video__sl-numb d-flex align-items-center justify-content-center">
                                                                        <div className="video__sl-numb-bg">
                                                                            <svg className="desk-only" width="142" height="164" viewBox="0 0 142 164" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M0.99485 41.5824L71 1.16495L141.005 41.5824V122.417L71 162.835L0.99485 122.417L0.99485 41.5824Z" fill="#F0DEC8" stroke="#262A5B" strokeWidth="2"/>
                                                                            </svg>
                                                                            <svg className="mb-only" width="47" height="53" viewBox="0 0 47 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M1.78711 14.0178L23.6269 1.40861L45.4667 14.0178V39.2362L23.6269 51.8454L1.78711 39.2362L1.78711 14.0178Z" fill="#F0DEC8" stroke="#262A5B" strokeWidth="2"/>
                                                                            </svg>
                                                                        </div>
                                                                        <span>{it.numb}</span>
                                                                    </div>
                                                                    <div className="video__sl-frame">
                                                                        <ReactPlayer
                                                                            className="react-player fadeIn animated"
                                                                            url={(window.matchMedia("(max-width: 1023px)").matches ? it.videoMb : it.video)}
                                                                            width='100%'
                                                                            height='100%'
                                                                            muted={true}
                                                                            playing={curSl === i && screen === 1}
                                                                            loop={true}
                                                                            playsinline={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="video__sl-descr text-center" dangerouslySetInnerHTML={{__html: it.descr}}/>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                {loadedSl && instanceRef.current && (
                                                    <div className="dots">
                                                        {[
                                                            ...Array(instanceRef.current.track.details.slides.length).keys(),
                                                        ].map((idx) => {
                                                            return (
                                                                <button
                                                                    key={idx}
                                                                    onClick={() => {
                                                                        instanceRef.current?.moveToIdx(idx)
                                                                    }}
                                                                    className={"dot" + (curSl === idx ? " active" : "")}
                                                                ></button>
                                                            )
                                                        })}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </ReactFullpage.Wrapper>
                            )
                        }}
                    />
                    <div className="alert__img-wrap d-flex justify-content-center fadeIn animated">
                        <div className="alert__img">
                            <img src={require("../assets/img/alert.svg").default}/>
                            {screen === 1 && (
                                <div className="rights fadeIn animated">
                                    ©2024 Hoegaarden. Все права защищены
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
