import { Routes, Route } from 'react-router-dom'
import React from 'react';
import Home from './pages/Home'
import {Helmet} from "react-helmet";

function App() {
  return (
      <>
          <Helmet>
              <title></title>
              <meta property="og:title" content=""/>
          </Helmet>
          <div id="wrapper" className="wrapper">
              <div className="decor decor--1"/>
              <div className="decor decor--2"/>
              <Routes>
                  <Route path="/" element={<Home />} />
              </Routes>
          </div>
      </>
  );
}

export default App;
